import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import HikingIcon from '@mui/icons-material/Terrain';
import CricketIcon from '@mui/icons-material/SportsCricket';
import TravelIcon from '@mui/icons-material/FlightTakeoff';
import FootballIcon from '@mui/icons-material/SportsSoccer';
import BookIcon from '@mui/icons-material/Book';
import Fade from 'react-reveal/Fade';

const interests = [
  { name: 'Hiking', icon: <HikingIcon fontSize="large" /> },
  { name: 'Cricket', icon: <CricketIcon fontSize="large" /> },
  { name: 'Traveling', icon: <TravelIcon fontSize="large" /> },
  { name: 'Football', icon: <FootballIcon fontSize="large" /> },
  { name: 'Reading Books', icon: <BookIcon fontSize="large" /> },
];

const Interests = () => (
  <Container id="interests" style={{ padding: '50px 0' }}>
    <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center', color: '#f50057' }}>
      Interests
    </Typography>
    <Grid container spacing={3}>
      {interests.map((interest) => (
        <Grid item xs={12} sm={6} md={4} key={interest.name} style={{ textAlign: 'center' }}>
          <Fade bottom>
            <Box>
              {interest.icon}
              <Typography variant="h6" color="textPrimary">{interest.name}</Typography>
            </Box>
          </Fade>
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default Interests;
