import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Fade from 'react-reveal/Fade';

const Footer = () => (
  <Fade bottom>
    <Box style={{ backgroundColor: '#121212', padding: '20px 0' }}>
      <Container>
        <Typography variant="body2" color="textSecondary" align="center">
          &copy; 2024 Prashant Neupane. All rights reserved.
        </Typography>
      </Container>
    </Box>
  </Fade>
);

export default Footer;
