import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import img1 from './../utils/img1.jpg';
import img2 from './../utils/img2.jpg';
import img3 from './../utils/img3.jpg';
import kubernetesLogo from './../utils/kubernetes-logo.svg'; // Import Kubernetes logo SVG
import githubLogo from './../utils/github-logo.svg'; // Import GitHub logo SVG
import linkedinLogo from './../utils/linkedin-logo.svg'; // Import LinkedIn logo SVG
import './LandingPage.css'; // Import CSS file for additional styling

const images = [img1, img2, img3]; // Array of carousel images
const intervalTime = 3000; // Interval time in milliseconds (5 seconds)

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0); // State to track current image index

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % images.length; // Calculate next index
      setCurrentIndex(nextIndex);
    }, intervalTime);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [currentIndex]); // Re-run effect whenever currentIndex changes

  return (
    <Container style={{ textAlign: 'center', marginTop: '20vh', position: 'relative' }}>
      {/* Social Media Links at the Top */}
      <Box className="social-media-links-top">
        <Link href="https://github.com/neupaneprashant22" target="_blank" rel="noopener noreferrer">
          <img src={githubLogo} alt="GitHub Logo" className="social-media-logo" />
        </Link>
        <Link href="https://www.linkedin.com/in/prashant-neupane-0422791a2/" target="_blank" rel="noopener noreferrer">
          <img src={linkedinLogo} alt="LinkedIn Logo" className="social-media-logo" />
        </Link>
      </Box>

      <Box className="carousel-container">
        <div className="carousel">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
              style={{
                transform: `rotateY(${currentIndex * (360 / images.length) - index * (360 / images.length)}deg) translateZ(300px)`,
              }}
            >
              <img
                className="carousel-image"
                src={image}
                alt={`{index + 1}`}
              />
            </div>
          ))}
        </div>
        <Typography variant="h4" color="textPrimary" style={{ marginTop: '20px' }}>
          Prashant Neupane
        </Typography>
        <Typography variant="body1" color="textSecondary" style={{ marginTop: '10px' }}>
          Self-motivated individual with a passion for leveraging cloud computing, DevOps, and cloud security to drive scalable solutions. Experienced in designing robust architectures and implementing efficient CI/CD pipelines. Enthusiastic about continuous learning, problem-solving, and collaborating in dynamic environments.
        </Typography>
      </Box>

      {/* Kubernetes Logo Animation */}
      <Box className="kubernetes-animation">
        <img src={kubernetesLogo} alt="Kubernetes Logo" className="kubernetes-logo" />
      </Box>
    </Container>
  );
};

export default LandingPage;
