import React from 'react';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';

const education = [
  {
    title: 'Bachelor in Computer Science and Information Technology',
    institution: 'Deerwalk Institute of Technology (DWIT)',
    period: '2018 - 2022'
  },
  {
    title: 'Science (+2)',
    institution: 'Prasadi Academy',
    period: '2016 - 2018'
  }
];

const Education = () => (
  <Container id="education" style={{ padding: '50px 0' }}>
    <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center', color: '#f50057' }}>
      Education
    </Typography>
    <Grid container spacing={3}>
      {education.map((edu) => (
        <Grid item xs={12} key={edu.title}>
          <Card style={{ backgroundColor: '#121212' }}>
            <CardContent>
              <Typography variant="h6" color="secondary">{edu.title}</Typography>
              <Typography variant="subtitle1" color="textSecondary">{edu.institution}</Typography>
              <Typography variant="subtitle2" color="textSecondary">{edu.period}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default Education;
