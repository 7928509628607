import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Link } from '@mui/material';
import Fade from 'react-reveal/Fade';

const projects = [
  {
    title: 'Nepali Number Plate Recognition',
    description: 'This project involves the recognition of Nepali number plates in both Devanagari and embossed formats using CNN for OCR, YOLO for object detection, and various morphing algorithms for image noise removal. The system can process both video and image inputs, and detected number plates can be downloaded as PDFs.',
    pdf: '/path/to/nepali_number_plate_recognition.pdf'
  },
  {
    title: 'Monitoring System for IT Infrastructure',
    description: 'Developed a real-time monitoring system to track server health, performance metrics, and system alerts. Integrated with Grafana for visualization and Prometheus for data collection.',
    pdf: '/path/to/monitoring_system.pdf'
  },
  {
    title: 'E-commerce Platform',
    description: 'Built a scalable e-commerce platform with features like user authentication, product management, order processing, and payment gateway integration. Utilized React for frontend and Node.js for backend.',
    pdf: '/path/to/e_commerce_platform.pdf'
  }
];

const Projects = () => (
  <Container id="projects" style={{ padding: '50px 0' }}>
    <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center' }}>
      Projects
    </Typography>
    <Grid container spacing={3}>
      {projects.map((project) => (
        <Grid item xs={12} sm={6} md={4} key={project.title}>
          <Fade bottom>
            <Card style={{ backgroundColor: '#242424', color: '#ffffff' }}>
              <CardContent>
                <Typography variant="h6" color="secondary">{project.title}</Typography>
                <Typography variant="body1" color="textSecondary">{project.description}</Typography>
                <Link href={project.pdf} target="_blank" style={{ marginTop: '10px', display: 'block', color: '#ff4081' }}>Read More</Link>
              </CardContent>
            </Card>
          </Fade>
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default Projects;
