import React from 'react';
import { Container, Typography, Card, CardContent, Grid, List, ListItem } from '@mui/material';
import Fade from 'react-reveal/Fade';
import imgDeerhold from './../utils/deerhold-logo.png'; // Replace with actual logo image
import imgDWIT from './../utils/dwit-logo.png'; // Replace with actual logo image
import './Experience.css'; // Import CSS file for additional styling

const experiences = [
  {
    company: 'Deerhold Nepal',
    logo: imgDeerhold,
    link: 'https://www.deerhold.com', // Replace with actual company link
    title: 'DevOps Engineer',
    period: '2022 - Current',
    tools: ['AWS (EC2, ECS, S3, Lambda)', 'Terraform', 'Docker', 'Linux', 'Bash', 'Jenkins'],
    details: [
      'Automated, optimized, and managed integration, deployment, and operations of software systems.',
      'Utilized AWS CloudFormation and Terraform for Infrastructure as Code (IaC).',
      'Set up and maintained CI/CD pipelines using AWS CodePipeline, CodeBuild, and CodeDeploy.',
      'Designed, implemented, and managed APIs using AWS API Gateway and AWS Lambda.',
      'Ensured high availability, scalability, and security of production systems.',
      'Collaborated closely with cross-functional teams to troubleshoot and resolve issues.'
    ]
  },
  {
    company: 'DWIT',
    logo: imgDWIT,
    link: 'https://deerwalk.edu.np/DWIT/', // Replace with actual company link
    title: 'DevOps Intern',
    period: '2020 - 2022',
    tools: ['Python', 'Flask', 'AWS (EC2, S3)', 'Linux', 'Docker', 'Git'],
    details: [
      'Collaborated with development teams to debug and resolve issues in existing applications.',
      'Created and hosted new web applications using JavaScript and managed AWS instances.',
      'Maintained documentation and provided support for IT infrastructure and networking tasks.',
      'Contributed to the implementation of CI/CD pipelines for automating build, test, and deployment processes.',
      'Assisted in the configuration and optimization of cloud-based environments.'
    ]
  }
];

const Experience = () => (
  <Container id="experience" style={{ padding: '50px 0' }}>
    <Typography variant="h3" className="experience-heading">
      Experience
    </Typography>
    <Grid container spacing={3}>
      {experiences.map((experience, index) => (
        <Grid item xs={12} key={index}>
          <Fade bottom>
            <Card className="experience-card">
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4} className="company-logo">
                    <a href={experience.link} target="_blank" rel="noopener noreferrer">
                      <img src={experience.logo} alt={experience.company} className="experience-logo" />
                    </a>
                    <Typography variant="body2" className="experience-period">
                      {experience.period}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h5" className="experience-role">
                      {experience.title}
                    </Typography>
                    <List className="experience-details">
                      {experience.details.map((detail, idx) => (
                        <ListItem key={idx}>
                          {detail}
                        </ListItem>
                      ))}
                    </List>
                    <Typography variant="body2" className="tools-used">
                      <strong>Tools & Technologies:</strong> {experience.tools.join(', ')}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fade>
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default Experience;
