import React from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './pages/LandingPage';
import ExperiencePage from './pages/ExperiencePage';
import SkillsPage from './pages/SkillsPage';
import ProjectsPage from './pages/ProjectsPage';
import EducationPage from './pages/EducationPage';
import InterestsPage from './pages/InterestsPage';
import Footer from './components/Footer';

function App() {
  return (
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<LandingPage/>} />
          <Route path="/experience" element={<ExperiencePage/>} />
          <Route path="/skills" element={<SkillsPage/>} />
          <Route path="/projects" element={<ProjectsPage/>} />
          <Route path="/education" element={<EducationPage/>} />
          <Route path="/interests" element={<InterestsPage/>} />
          </Routes>
        <Footer />
      </Router>
  );
}

export default App;
