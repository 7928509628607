import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import Fade from 'react-reveal/Fade';

const skillsData = [
  {
    category: 'Cloud Platforms',
    skills: [
      { name: 'AWS' },
      { name: 'Azure' },
      { name: 'GCP'},
    ]
  },
  {
    category: 'Databases',
    skills: [
      { name: 'MongoDB'},
      { name: 'MySQL' },
      { name: 'ElasticSearch' },
    ]
  },
  {
    category: 'Programming Languages',
    skills: [
      { name: 'HTML/CSS' },
      { name: 'NodeJS' },
      { name: 'React'},
    ]
  },
  {
    category: 'Other Skills',
    skills: [
      { name: 'GitHub'},
      { name: 'Data Structures and Algorithms' },
      { name: 'REST API / FastAPI' },
      { name: 'Pandas / Scikit learn'},
      { name: 'Linux / Hardware Understanding'},
    ]
  }
];

const Skills = () => (
  <Container id="skills" style={{ padding: '50px 0' }}>
    <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center', color: '#f50057' }}>
      Skills
    </Typography>
    {skillsData.map((category) => (
      <Box key={category.category} style={{ marginBottom: '30px' }}>
        <Typography variant="h5" color="secondary" style={{ marginBottom: '10px' }}>
          {category.category}
        </Typography>
        <Grid container spacing={3}>
          {category.skills.map((skill) => (
            <Grid item xs={12} sm={6} key={skill.name}>
              <Fade bottom>
                <Box style={{ marginBottom: '20px' }}>
                  <Typography variant="h6" color="textPrimary">{skill.name}</Typography>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Box style={{ flexGrow: 1, marginRight: '10px', backgroundColor: '#fff', height: '8px', borderRadius: '4px', overflow: 'hidden' }}>
                      <Box style={{ width: `${skill.rating / 5 * 100}%`, height: '100%', backgroundColor: '#f50057' }}></Box>
                    </Box>
                    <Typography variant="body2" color="textSecondary">{skill.rating}</Typography>
                  </Box>
                </Box>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Box>
    ))}
  </Container>
);

export default Skills;
