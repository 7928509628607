import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Switch, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';

const Header = () => {
  const { darkMode, setDarkMode } = useContext(ThemeContext);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1, color: darkMode ? '#ffffff' : '#000000' }}>
            Prashant Neupane
          </Typography>
          <Button color="inherit" component={Link} to="/">Home</Button>
          <Button color="inherit" component={Link} to="/experience">Experience</Button>
          <Button color="inherit" component={Link} to="/skills">Skills</Button>
          <Button color="inherit" component={Link} to="/projects">Projects</Button>
          <Button color="inherit" component={Link} to="/education">Education</Button>
          <Button color="inherit" component={Link} to="/interests">Interests</Button>
          <Switch checked={darkMode} onChange={handleThemeChange} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
